<template>
  <div id="welcome">
    <el-breadcrumb
            separator="/"
            style="padding-left: 10px; padding-bottom: 10px; font-size: 12px"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>概况</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="15">
        <!-- 左边部分 -->
        <el-col :span="13">
          <!-- 用户信息表格 -->
        </el-col>
      </el-row>
      <div slot="header" class="clearfix">
        <span>概况</span>
      </div>
      <!--</div>-->
    </el-card>
    <!-- 功能列表 -->
    <el-row style="margin-top: 10px" :gutter="10">

    </el-row>
  </div>
</template>
<script>
  import Schart from 'vue-schart';
  export default {
    data() {
      return {}

    },
    methods: {

    },

    mounted() {

    },
  };
</script>

<style scoped>
  .grid-content {
    display: flex;
    align-items: center;
    height: 100px;
  }

  .grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
  }

  .grid-num {
    font-size: 30px;
    font-weight: bold;
  }

  .grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }

  .grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
  }

  .grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
  }

  .grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
  }

  .grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
  }

  .grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
  }

  .grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
  }

  .schart {
    width: 100%;
    height: 400px;
  }
</style>
